import React from "react";
import styles from "./IconButton.module.css";
import Button from "../Button/Button";
import Icon from "../Icon";

const IconButton = ({ icon, type, isClickable, onClick }) => {
  return (
    <Button className={styles.wrapper} type={type} isClickable={isClickable} onClick={onClick}>
      <Icon name={icon} color={"currentColor"} />
    </Button>
  );
}

export default IconButton;