/**
 * Класс, представляющий объект расписания.
 */
export default class TimetableObject {
  /**
   * Ключ для сохранения/извлечения данных из локального хранилища.
   *
   * @constant
   * @type {string}
   */
  static STORAGE_KEY = "timetableObject";

  constructor(id, type, name, shortName) {
    /**
     * Тип объекта.
     *
     * @type {string}
     * @description Например, "students", "teachers", "classrooms".
     */
    this.type = type;

    /**
     * Уникальный идентификатор объекта.
     *
     * @type {number}
     */
    this.id = id;

    /**
     * Название объекта.
     *
     * @type {string}
     */
    this.name = name;

    /**
     * Сокращённое название объекта.
     *
     * @type {string}
     */
    this.shortName = shortName;

    /**
     * Флаг, указывающий, что объект является аудиторией.
     *
     * @type {boolean}
     */
    this.isClassroom = this.type === "classrooms";
  }

  /**
   * Сохраняет данные объекта в локальное хранилище.
   */
  saveToLocalStorage() {
    const value = JSON.stringify({
      id: this.id,
      type: this.type
    });

    localStorage.setItem(TimetableObject.STORAGE_KEY, value);
  }

  /**
   * Создает новый экземпляр объекта на основе переданных данных.
   *
   * @param {Object} obj Объект с данными.
   * @return {TimetableObject} Экземпляр объекта расписания.
   */
  static parse(obj) {
    return new this(obj.id, obj.type, obj.name, obj.shortName);
  }

  /**
   * Получает данные сохранённого объекта из локального хранилища.
   *
   * @return {Object|null} Данные объекта либо `null`, если они не сохранены.
   */
  static getFromLocalStorage() {
    const value = localStorage.getItem(this.STORAGE_KEY);

    if (value) {
      const jsonObject = JSON.parse(value);

      return {
        id: parseInt(jsonObject.id),
        type: jsonObject.type
      };
    } else {
      return null;
    }
  }

  /**
   * Удаляет сохранённый объект из локального хранилища.
   */
  static removeFromLocalStorage() {
    localStorage.removeItem(this.STORAGE_KEY);
  }
}