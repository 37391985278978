import React, { useEffect, useMemo, useState } from "react";
import styles from "./SearchPage.module.css";
import ListItem from "../../../api/data/ListItem";
import Title from "../../components/Title/Title";
import CustomList from "../../components/CustomList/CustomList";
import Spinner from "../../components/Spinner/Spinner";
import Icon from "../../components/Icon";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import request from "../../../api/utils/request";
import { useAppNav } from "../../../api/hooks/useAppNav";
import { useSearchParams } from "react-router-dom";
import useTitle from "../../../api/hooks/useTitle";
import IconButton from "../../components/IconButton/IconButton";
import ButtonType from "../../../api/constants/ButtonType";
import Button from "../../components/Button/Button";
import Link from "../../components/Link";
import useResponse from "../../../api/hooks/useResponse";
import { useAppContext } from "../../../App";
import SearchInput from "../../components/SearchInput/SearchInput";

/**
 * Компонент страницы поиска.
 *
 * @return {Element} Элемент страницы.
 */
const SearchPage = () => {
  const { setErrorMessage } = useAppContext();
  const { navToTimetable } = useAppNav();
  const { isDesktop } = useResponse();

  const [searchParams] = useSearchParams();
  const [searchObjectName, setSearchObjectName] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isValidSearchQuery, setIsValidSearchQuery] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(true);
  const [resultsListState, setResultsListState] = useState({
    items: [],
    subItems: [],
    lastIndex: -1,
    selectedIndex: -1,
    isFocused: false
  });
  const [activeAdditionalTab, setActiveAdditionalTab] = useState(0);

  // Устанавливаем заголовок документа
  useTitle("Поиск");

  // Слушаем изменение URL-параметров
  useEffect(() => {
    const query = searchParams.get("q") ?? "";

    setSearchObjectName(query);
    setSearchQuery(query);
  }, [searchParams]);

  // Отслеживаем нажатие клавиш
  useEffect(() => {
    const handleKeyDown = (e) => {
      const key = e.key;

      if (key === "ArrowDown") {
        selectResultsListItem(true);
      } else if (key === "ArrowUp") {
        selectResultsListItem(false);
      } else if (key === "Enter") {
        handleListItemClick();
        // if (responseData?.length === 1) {
        //   if (responseData[0]["subgroups"]) {
        //     setSearchObjectName(responseData[0]["name"]);
        //   } else {
        //     navToTimetable(responseData[0]["type"], responseData[0]["id"]);
        //   }
        // }
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [resultsListState.selectedIndex, resultsListState.lastIndex]);

  // Отслеживаем изменение значения поля ввода
  useEffect(() => {
    const hasValue = searchQuery.trim().length > 1;

    setIsValidSearchQuery(hasValue);

    if (hasValue) {
      setResponseData(null);
      setIsSearching(true);
      request.search(searchQuery, (response, error) => {
        (response?.result) ? setResponseData(response.result) : setErrorMessage(error);
        setIsSearching(false);
      });
    }

    return () => request.cancel();
  }, [searchQuery, setErrorMessage]);

  // Отслеживаем изменение запрашиваемых данных
  useEffect(() => {
    updateResultsListItems(responseData);
  }, [responseData]);

  // Отслеживаем название запрашиваемого объекта по GET-запросу
  useEffect(() => {
    const items = resultsListState.items;

    if (items.length > 0 && items[0].text.toLowerCase() === searchObjectName.toLowerCase()) {
      handleListItemClick(0);
    }
  }, [resultsListState.items, searchObjectName]);

  /**
   * Извлекает элементы списка из массива указанных объектов.
   *
   * @param {Object[]} objects Массив объектов, из которого необходимо извлечь элементы.
   * @return {ListItem[]} Возвращает массив элементов списка с иконками.
   */
  const parseListItems = (objects) => objects?.map(({ subgroups, name }) => {
    const icon = (subgroups?.length > 0) ? ListItem.ICON_ARROW : ListItem.ICON_NONE;

    return new ListItem(name, icon, null);
  });

  /**
   * Обновляет состояние списка результатов.
   *
   * @param {Object} newState Объект с новыми значениями для обновления состояния.
   */
  const updateResultsListState = (newState) => {
    setResultsListState(prevState => ({
      ...prevState,
      ...newState
    }));
  }

  /**
   * Обновляет список элементов.
   *
   * @param {Object[]} data Данные, из которых необходимо извлечь элементы списка.
   */
  const updateResultsListItems = (data = responseData) => {
    if (data) {
      const items = parseListItems(data);

      updateResultsListState({
        items: items,
        selectedIndex: -1,
        lastIndex: items ? items.length - 1 : -1
      });
    }
  }

  /**
   * Убирает фокус со списка результатов.
   */
  const blurResultsList = () => {
    if (resultsListState.isFocused && resultsListState.selectedIndex !== -1) {
      updateResultsListState({
        isFocused: false,
        selectedIndex: -1
      });
    }
  }

  /**
   * Выделяет следующий либо предыдущий элемент списка.
   *
   * @param {boolean} nextItem `true` - выделяет следующий элемент, `false` - предыдущий.
   */
  const selectResultsListItem = (nextItem) => {
    if (isValidSearchQuery) {
      setIsInputFocused(false);

      const selectedIndex = resultsListState.selectedIndex;
      const lastIndex = resultsListState.lastIndex;
      const prevIndex = (selectedIndex > 0) ? selectedIndex - 1 : lastIndex;
      const nextIndex = (selectedIndex < lastIndex) ? selectedIndex + 1 : 0;

      updateResultsListState({
        isFocused: true,
        isScrollingDown: nextItem,
        selectedIndex: nextItem ? nextIndex : prevIndex
      });
    }
  }

  /**
   * Обрабатывает нажатие на элемент списка.
   *
   * @param {number} index Индекс нажатого элемента. По умолчанию равен индексу выделенного элемента.
   */
  const handleListItemClick = (index = resultsListState.selectedIndex) => {
    if (resultsListState.subItems.length > 0) {
      const data = resultsListState.subItems[index];

      if (data) {
        navToTimetable(data["type"], data["id"]);
      }
    } else {
      const data = responseData[index];

      if (data) {
        const subgroups = data["subgroups"];
        const hasSubgroups = subgroups?.length > 0;

        updateResultsListState({ subItems: subgroups });

        if (hasSubgroups) {
          updateResultsListItems(subgroups);
        } else {
          navToTimetable(data["type"], data["id"]);
        }
      }
    }
  }

  /**
   * Компонент для отображения результатов поиска.
   *
   * @return {Element} Элемент списка либо сообщения об отсутствии результатов.
   */
  const Result = () => {
    /**
     * Компонент для отображения списка результатов поиска.
     *
     * @param {ListItem[]} items Массив отображаемых элементов.
     * @param {boolean} backButton Флаг, указывающий на видимость кнопки "Назад".
     * @param {Function} onItemClick Функция обратного вызова, вызываемая при нажатии на элемент списка.
     * @param {Function} onBackButtonClick Функция обратного вызова, вызываемая при нажатии кнопки "Назад".
     * @return {Element} Элемент списка.
     */
    const List = ({ items, backButton, onItemClick, onBackButtonClick }) => {
      /**
       * Компонент для отображения верхней панели списка.
       *
       * @return {Element} Элемент верхней панели.
       */
      const TopBar = () => {
        return (
          <div className={styles.topBarContainer}>
            {backButton ? (
              <div className={styles.backTitle} onClick={onBackButtonClick}>
                <Icon name={"chevron-left"} size={12} color={"currentColor"}/>
                Назад
              </div>
            ) : (
              <div className={styles.resultTitle}>Результаты поиска</div>
            )}
          </div>
        );
      }

      const handleClick = () => {
        updateResultsListState({ isFocused: true });
      }

      return (
        <div className={styles.listContainer} onClick={handleClick}>
          <TopBar />
          <CustomList
            items={items}
            selectedItem={resultsListState.selectedIndex}
            height={"200px"}
            onItemClick={onItemClick}
          />
        </div>
      );
    }

    /**
     * Обрабатывает нажатие кнопки "Назад" списка.
     */
    const handleBackButtonClick = () => {
      updateResultsListState({ subItems: [] });
      updateResultsListItems();
    }

    return (
      <>
        {resultsListState.items.length > 0 ? (
          <List
            items={resultsListState.items}
            backButton={resultsListState.subItems.length > 0}
            onItemClick={handleListItemClick}
            onBackButtonClick={handleBackButtonClick}
          />
        ) : (
          <div className={styles.notFoundContainer}>
            <Title level={2}>Ничего не найдено</Title>
            <div className={styles.notFoundText}>Проверьте, нет ли ошибок в введённом запросе</div>
          </div>
        )}
      </>
    );
  }

  /**
   * Компонент для отображения блока с дополнительными разделами (информацией).
   *
   * @return {Element} Элемент секции.
   * @constructor
   */
  const AdditionalSections = () => {
    const [isLoading, setIsLoading] = useState(false);

    /**
     * Компонент для осуществления навигации по вкладкам.
     *
     * @return {Element} Элемент навигации.
     * @constructor
     */
    const Nav = () => {
      const Item = ({ index, children: text }) => {
        const isActive = index === activeAdditionalTab;
        const buttonType = isActive ? ButtonType.FILLED : ButtonType.TONAL;
        let classNames = styles.additionalNavItem;

        if (isActive) {
          classNames += ` ${styles.additionalNavItemActive}`;
        }

        const handleClick = () => {
          //setIsLoading(true);
          setActiveAdditionalTab(index);
        }

        return (
          <li className={classNames} onClick={handleClick}>
            {text}
            <IconButton
              icon={`chevron-${isDesktop ? "right" : "down"}`}
              type={buttonType}
              isClickable={false}
            />
          </li>
        );
      }

      return (
        <nav className={styles.additionalNavTabs}>
          <Item index={0}>Расписание звонков</Item>
          <Item index={1}>Буквенные обозначения кабинетов</Item>
          <Item index={2}>Расписание проведения ГИА</Item>
        </nav>
      );
    }

    /**
     * Компонент для отображения учебных корпусов и их обозначений.
     *
     * @return {Element} Элемент содержимого вкладки "Буквенные обозначения кабинетов".
     * @constructor
     */
    const Campuses = () => {
      const Item = ({ name, letters }) => {
        const lastIndexLetter = letters.length - 1;

        const Letter = ({ icon, orSeparator }) => {
          const props = {
            type: ButtonType.OUTLINED,
            isClickable: false
          };

          return (
            <>
              {icon !== "" ?
                <IconButton icon={icon} {...props} />
                :
                <Button {...props}>Нет буквы</Button>
              }
              {orSeparator && <span>или</span>}
            </>
          );
        }

        return (
          <li className={styles.campusItem}>
            {name}
            <div className={styles.campusItemLetters}>
              {letters?.map((letter, index) => {
                return <Letter icon={letter} orSeparator={index !== lastIndexLetter} key={index} />;
              })}
            </div>
          </li>
        );
      }

      return (
        <ul className={styles.additionalList}>
          <Item
            name={"Главный учебный корпус (ГУК), Советский проспект, 1"}
            letters={["", "г"]}
          />
          <Item
            name={"Учебный корпус № 1, ул. Профессора Баранова, 43 "}
            letters={["б"]}
          />
          <Item
            name={"Учебный корпус № 2, Малый переулок, 32 (СК — Спортивный комплекс)"}
            letters={["м", "ск"]}
          />
          <Item
            name={"Учебный корпус № 3 ул. Калязинская, 4"}
            letters={["к"]}
          />
          <Item
            name={"Корпус № 1 БГАРФ, ул. Молодёжная, 6"}
            letters={["а"]}
          />
          <Item
            name={"Корпус № 2 БГАРФ, ул. Озёрная, 30"}
            letters={["а2"]}
          />
          <Item
            name={"Корпус № 3 БГАРФ, ул. Озёрная, 32"}
            letters={["а3"]}
          />
        </ul>
      );
    }

    /**
     * Компонент для отображения расписания проведения ГИА (Государственная итоговая аттестация).
     *
     * @return {Element} Элемент содержимого вкладки "Расписание проведение ГИА".
     * @constructor
     */
    const GIASchedule = ({ items }) => {
      const FilesList = () => {
        const Item = ({ url, title, description }) => {
          return (
            <li className={styles.giaItem}>
              <Link url={url} newTab={true}>
                <div className={styles.giaItemHead}>
                  <IconButton icon={"download"} type={ButtonType.TONAL} isClickable={false} />
                  {title}
                </div>
                {description}
              </Link>
            </li>
          );
        }

        return (
          <>
            {items ? (
              <ul className={styles.additionalList}>
                {items.map((props, index) => {
                  return <Item {...props} key={index} />
                })}
              </ul>
            ) : (
              <div className={styles.giaNoTimetableContainer}>
                <Icon name={"calendar-minus"} size={40} />
                <Title level={2}>Расписания пока нет</Title>
              </div>
            )}
          </>
        );
      }

      return isLoading ? <Spinner /> : <FilesList />;
    }

    /**
     * Компонент для отображения расписания звонков.
     *
     * @return {Element} Элемент содержимого вкладки "Расписание звонков".
     * @constructor
     */
    const BellsSchedule = () => {
      return (
        <table className={styles.bellsTable}>
          <thead>
            <tr>
              <th>Пара</th>
              <th>1-ая полупара</th>
              <th>2-ая полупара</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>9:00 – 9:40</td>
              <td>9:45 – 10:25</td>
            </tr>
            <tr>
              <td>2</td>
              <td>10:35 – 11:15</td>
              <td>11:20 – 12:00</td>
            </tr>
            <tr>
              <td>3</td>
              <td>12:10 – 12:50</td>
              <td>12:55 – 13:35</td>
            </tr>
            <tr>
              <td>—</td>
              <td colSpan="2">13:35 – 14:15 (перерыв)</td>
            </tr>
            <tr>
              <td>4</td>
              <td>14:15 – 14:55</td>
              <td>15:00 – 15:40</td>
            </tr>
            <tr>
              <td>5</td>
              <td>15:50 – 16:30</td>
              <td>16:35 – 17:15</td>
            </tr>
            <tr>
              <td>6</td>
              <td>17:25 – 18:05</td>
              <td>18:10 – 18:50</td>
            </tr>
            <tr>
              <td>7</td>
              <td>19:00 – 19:40</td>
              <td>19:45 – 20:25</td>
            </tr>
            <tr>
              <td>8</td>
              <td>20:30 – 21:10</td>
              <td>21:15 – 21:55</td>
            </tr>
          </tbody>
        </table>
      );
    }

    /**
     * Компонент для отображения содержимого вкладки.
     *
     * @return {Element} Элемент содержимого вкладки.
     * @constructor
     */
    const Content = () => {
      let scrollClasses = "custom-scroll";

      // Если активна вкладка "Раписание звонков",
      // то дополнительно стилизуем скролл
      if (activeAdditionalTab === 0) {
        scrollClasses += ` ${styles.bellsScroll}`;
      }

      const classNames = `${styles.additionalTabContent} ${scrollClasses}`;

      return (
        <div className={styles.additionalContentWrapper}>
          <div className={classNames}>
            {tabsContent[activeAdditionalTab]}
          </div>
        </div>
      );
    }

    const tabsContent = [<BellsSchedule />, <Campuses />, <GIASchedule />];

    return (
      <section className={styles.additionalContainer}>
        <div className={styles.additionalColumn}>
          <div className={styles.additionalHead}>
            <Title tag={"h2"} level={2}>Дополнительные разделы</Title>
            <div className={styles.additionalHeadText}>Прочая информация, которая может пригодиться</div>
          </div>
          <Nav />
        </div>
        <div className={styles.additionalColumn}>
          <Content />
        </div>
      </section>
    );
  }

  /**
   * Обрабатывает выход курсора за пределы контейнера поиска.
   */
  const handleMouseLeave = () => {
    setResponseData(null);
    setIsValidSearchQuery(false);
  }

  /**
   * Обрабатывает изменение значения в поле ввода поиска.
   *
   * @param {string} newValue Введённое значение.
   */
  const handleInputChange = (newValue) => {
    setSearchQuery(newValue);
  }

  return (
    <>
      <Header/>
      <main className={styles.main}>
        <section className={styles.searchContainer} onMouseLeave={handleMouseLeave}>
          <div className={styles.cover}>
            <Title tag={"h1"} level={1}>Поиск расписания</Title>
            <div className={styles.coverText}>
              Введите название учебной группы, ФИО преподавателя либо номер кабинета
            </div>
          </div>
          <div className={styles.inputGroupWrapper}>
            <SearchInput
              defaultValue={searchQuery}
              onChange={handleInputChange}
              onFocus={blurResultsList}
              isFocused={isInputFocused}
            />
            {isValidSearchQuery && (
              <div className={styles.resultContainer}>
                {isSearching ? (
                  <div className={styles.notFoundContainer}>
                    <Spinner />
                  </div>
                ) : (
                  <Result />
                )}
              </div>
            )}
          </div>
        </section>
        <AdditionalSections />
      </main>
      <Footer responsiveWide={false} links={true} />
    </>
  );
}
export default SearchPage;