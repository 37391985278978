import React from "react";
import styles from "./Logo.module.css";
import Link from "../Link";

/**
 * Компонент для отображения логотипа.
 *
 * @return {Element} Возвращает элемент логотипа.
 * @constructor
 */
const Logo = () => {
  return (
    <Link url="/">
      <div className={styles.logo} />
    </Link>
  );
}

export default Logo;