import { useMediaQuery } from "usehooks-ts";

/**
 * Хук для определения типа устройства на основе медиазапросов.
 *
 * @returns {Object} Объект с булевыми значениями, которые указывают,
 * является ли текущее устройство планшетом или мобильным:
 * - `isDesktop` {boolean} - Истина, если ширина экрана больше 820px.
 * - `isTablet` {boolean} - Истина, если ширина экрана меньше или равна 820px.
 * - `isMobile` {boolean} - Истина, если ширина экрана меньше или равна 540px.
 */
const useResponse = () => {
  const isTablet = useMediaQuery("(max-width: 820px)");
  const isMobile = useMediaQuery("(max-width: 540px)");
  const isDesktop = !isTablet && !isMobile;

  return { isDesktop, isTablet, isMobile };
};

export default useResponse;