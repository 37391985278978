import React, { useEffect, useRef } from "react";
import styles from "./CustomList.module.css";
import ListItem from "../../../api/data/ListItem";

/**
 * Компонент пользовательского списка данных.
 *
 * @param {ListItem[]} items Массив элементов списка.
 * @param {string} className Класс списка.
 * @param {number} selectedItem Индекс выделенного элемента.
 * @param {Function} onItemClick Обратный вызов, возвращающий индекс нажатого элемента.
 * @param {string} height Высота списка. По умолчанию "auto".
 * @return {Element} Возвращает элемент списка.
 */
const CustomList = ({ className, items, selectedItem , onItemClick, height = "auto" }) => {
  const listRef = useRef(null);

  useEffect(() => {
    scrollToIndex(selectedItem);
  }, [selectedItem]);

  const scrollToIndex = (index) => {
    const list = listRef.current;

    if (!list || index === -1) return;

    const item = list.children[index];

    if (item) {
      const listRect = list.getBoundingClientRect();
      const itemRect = item.getBoundingClientRect();
      const itemBottom = itemRect.bottom - itemRect.height;

      if (itemRect.top < listRect.top || itemBottom > listRect.bottom) {
        item.scrollIntoView({ block: "end" });
      }
    }
  };

  /**
   * Компонент представляет отдельный элемент списка.
   *
   * @param {number} index Присваиваемый индекс элемента.
   * @param {ListItem} data Данные элемента.
   * @return {Element} Возвращает элемент списка.
   */
  const Item = ({ index, data }) => {
    let className = styles.item;

    // Показываем иконку справа при необходимости
    if (data.icon !== ListItem.ICON_NONE) {
      className += " " + styles[data.icon];
    }

    if (index === selectedItem) {
      className += " " + styles.selected;
    }

    /**
     * Обарабатывает нажатие элемента.
     *
     * @param {number} index Индекс нажатого элемента.
     * @param {Object} data Данные нажатого элемента.
     */
    const handleClick = (index, data) => onItemClick(index, data);

    return (
      <li className={className} onClick={() => handleClick(index, data.data)}>
        {data.text}
      </li>
    );
  }

  const style = {
    height: height
  };
  let classNames = `${styles.list} custom-scroll`;

  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <ul className={classNames} ref={listRef} style={style}>
      {items?.map((item, index) => (
        <Item index={index} data={item} key={index} />
      ))}
    </ul>
  );
}

export default CustomList;