import React, { useEffect, useRef, useState } from "react";
import styles from "./SearchInput.module.css";
import Icon from "../Icon";

/**
 * Компонент для отображения поля ввода поиска.
 *
 * @param {Function} onChange Функция обратного вызова, вызываемая при изменении значения поля ввода.
 * @param {Function} onFocus Функция обратного вызова, вызываемая при фокусировке на поле ввода.
 * @param {string} defaultValue Установленное значение по умолчанию.
 * @param {boolean} isFocused Флаг, указывающий на наличие фокуса в поле ввода. По умолчанию `true`.
 * @return {Element} Элемент поля ввода для поиска.
 */
const SearchInput = ({ onChange, onFocus,  defaultValue = "", isFocused = true }) => {
  const [value, setValue] = useState("");

  const ref = useRef(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    isFocused ? setFocus() : clearFocus();
  }, [isFocused]);

  const setFocus = () => {
    ref.current?.focus();
  }

  const clearFocus = () => {
    ref.current?.blur();
  }

  const updateValue = (newValue) => {
    setValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  }

  const handleChange = (e) => {
    updateValue(e.target.value);
  }

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  }

  /**
   * Компонент для отображения кнопки очистки поля ввода.
   *
   * @return {Element} Элемент кнопки.
   */
  const ClearButton = () => {
    const className = (value.length > 0) ? styles.clearButton : styles.hidden;

    const handleClick = () => {
      updateValue("");
      setFocus();
    }

    return (
      <button className={className} onClick={handleClick}>
        <Icon name={"circle-xmark"} size={16} />
      </button>
    );
  }

  return (
    <div className={styles.wrapper} onClick={setFocus}>
      <Icon name={"magnifying-glass"} className={styles.inputIcon} size={20} />
      <input
        ref={ref}
        className={styles.input}
        type={"text"}
        value={value}
        placeholder={"Группа, преподаватель, аудитория"}
        onChange={handleChange}
        onFocus={handleFocus}
      />
      <ClearButton />
    </div>
  );
}

export default SearchInput;