import React from "react";
import styles from "./NotFoundPage.module.css";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon";
import useTitle from "../../../api/hooks/useTitle";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

/**
 * Компонент страницы 404 ошибки.
 *
 * @return {Element} Элемент страницы.
 */
const NotFoundPage = () => {
  const navigate = useNavigate();

  useTitle("Страница не найдена");

  const handleButtonClick = () => navigate("/");

  return (
    <>
      <Header backButton={false} />
      <main className={styles.main}>
        <div className={styles.cover}>
          <div className={styles.image}/>
          <div className={styles.text}>Похоже, мы не можем найти нужную Вам страницу</div>
        </div>
        <Button onClick={handleButtonClick}>
          <Icon name={"home"} size={16} color={"currentColor"}/> На главную
        </Button>
      </main>
      <Footer responsiveWide={false} links={true}/>
    </>
  );
}

export default NotFoundPage;