import React from "react";
import styles from "./Modal.module.css";
import Icon from "../Icon";
import { useEffect } from "react";

/**
 * Компонент для отображения модального окна.
 *
 * @param {React.ReactChildren} content Содержимое окна.
 * @param {string} title Текст заголовка окна.
 * @param {boolean} isVisible Флаг, указывающий на показ окна.
 * @param {Function} onClose Обратный вызов, вызываемый при нажатии кнопки закрытия окна.
 * @return {Element} Элемент модального окна.
 * @constructor
 */
const Modal = ({ children: content, title, isVisible, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = isVisible ? "hidden" : "auto";

    return () => {
      document.body.style.overflow = "auto";
    }
  }, [isVisible]);

  const Header = () => {
    const Title = () => <div className={styles.title}>{title}</div>;
    const CloseButton = () => {
      return (
        <button className={styles.closeButton} onClick={onClose}>
          <Icon name={"x-close"} size={18} />
        </button>
      );
    }

    return (
      <div className={styles.header}>
        <Title />
        <CloseButton />
      </div>
    );
  }

  const Body = () => <div className={styles.body}>{content}</div>;

  const handleClick = (e) => {
    if (e.target.classList.contains(styles.overlay)) {
      onClose();
    }
  }

  return (
    <>
      {isVisible && (
        <div className={styles.overlay} onClick={handleClick}>
          <div className={styles.container}>
            <Header />
            <Body />
          </div>
        </div>
      )}
    </>
  );
}

export default Modal;