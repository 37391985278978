import React from "react";
import styles from "./Footer.module.css";
import Logo from "../Logo/Logo";
import Icon from "../Icon";
import { useAppContext } from "../../../App";
import ModalId from "../../../api/constants/ModalId";
import Link from "../Link";

/**
 * Компонент для отображения подвала.
 *
 * @param {boolean} logo Флаг, указывающий на отображение логотипа.
 * @param {boolean} responsiveWide Флаг, указывающий на стиль футера по всей ширине в адаптивной версии.
 * @param {boolean} links Флаг, указывающий на отображение ссылок.
 * @param {boolean} toTopButton Флаг, указывающий на видимость кнопки прокрутки вверх.
 * @return {Element} Элемент подвала.
 */
const Footer = ({ logo, responsiveWide, links, toTopButton }) => {
  const { showModal } = useAppContext();

  /**
   * Компонент для отображения ссылок.
   *
   * @return {Element} Элемент контейнера ссылок.
   */
  const Links = () => {
    const Item = ({ children, iconName, url }) => {
      return (
        <Link className={styles.linkItem} url={url} newTab={true}>
          <Icon name={iconName} color={"currentColor"} />
          {children}
        </Link>
      );
    }

    return (
      <div className={styles.linksContainer}>
        <Item iconName={"bug"} url={"https://forms.yandex.ru/cloud/65e1b1b0693872b03e70d20f/"}>
          Нашли ошибку?
        </Item>
        <Item iconName={"lamp"} url={"https://forms.yandex.ru/cloud/65e1b231c769f1ab1334d231/"}>
          Есть идеи?
        </Item>
        <Item iconName={"old-age"} url={"https://rasp.klgtu.ru/old"}>
          Старая версия
        </Item>
      </div>
    );
  }

  /**
   * Компонент для отображения копирайта.
   *
   * @return {Element} Элемент копирайта.
   */
  const Copyright = () => {
    const handleClick = () => showModal(ModalId.DEV_TEAM);
    
    return (
      <div className={styles.copyrightText}>
        Разработано <span onClick={handleClick}>ФГБОУ ВО «КГТУ»</span>
      </div>
    );
  }

  /**
   * Компонент для отображения кнопки прокрутки вверх.
   *
   * @return {Element} Элемент кнопки.
   */
  const ToTopButton = () => {
    const handleClick = () => {
      document.body.scrollIntoView({
        behavior: "smooth",
      });
    }

    return (
      <button className={styles.toTopButton} onClick={handleClick}>
        <Icon name={"arrow-up"} />
      </button>
    );
  }

  let wrapperClasses = styles.wrapper;
  let containerClasses = styles.container;

  if (responsiveWide) {
    wrapperClasses += ` ${styles.wrapperWide}`;
    containerClasses += ` ${styles.containerWide}`;
  }

  return (
    <footer className={wrapperClasses}>
      <div className={containerClasses}>
        {logo && <Logo />}
        {links && <Links />}
        <Copyright/>
        {toTopButton && <ToTopButton />}
      </div>
    </footer>
  );
}

export default Footer;