/**
 * Класс, представляющий элемент списка.
 */
export default class ListItem {
  constructor(text, icon, data) {
    /**
     * Текст элемента.
     *
     * @type {string}
     */
    this.text = text;

    /**
     * Иконка элемента справа.
     *
     * @type {string}
     */
    this.icon = icon;

    /**
     * Данные элемента.
     *
     * @type {Object}
     */
    this.data = data;
  }

  /**
   * Без иконки.
   *
   * @constant
   * @type {string}
   */
  static ICON_NONE = "none";

  /**
   * Иконка стрелки вправо.
   *
   * @constant
   * @type {string}
   */
  static ICON_ARROW = "arrow";

  /**
   * Иконка галочки.
   *
   * @constant
   * @type {string}
   */
  static ICON_CHECK = "check";

  static parseItems(arr, textKey) {
    return arr?.map(data => {
      return new ListItem(data[textKey], ListItem.ICON_NONE, data);
    });
  }
}
