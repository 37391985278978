import React from "react";
import styles from "./Button.module.css";
import ButtonType from "../../../api/constants/ButtonType";

const Button = ({ className, children, onClick, type = ButtonType.FILLED, isClickable = true }) => {
  const style = {
    pointerEvents: isClickable ? "auto" : "none"
  };
  let classNames = `${styles.wrapper} ${styles[type]}`;

  if (className) {
    classNames += ` ${className}`;
  }

  return <button className={classNames} style={style} onClick={onClick}>{children}</button>;
}

export default Button;