/**
 * Объект для работы с датой и временем в формате ISO 8601.
 */
const dateTime = {
  /**
   * Используемый разделитель.
   *
   * @constant
   * @type {string}
   */
  SEPARATOR: "–",

  /**
   * Добавляет ведущий ноль к числу, если оно меньше 10.
   *
   * @param {number} number Число, к которому нужно добавить ведущий ноль.
   * @return {string} Строка с нулём либо с самим числом.
   */
  addLeadingZero(number) {
    return number.toString().padStart(2, "0");
  },

  /**
   * Проверяет равенство дат без учёта времени.
   *
   * @param {string} date1 Первая дата в формате строки.
   * @param {string} date2 Вторая дата в формате строки.
   * @return {boolean} Возвращает `true`, если даты равны по дню, и `false` в противном случае.
   */
  isDateEquals(date1, date2) {
    const separator = "T";

    return date1.split(separator)[0] === date2.split(separator)[0];
  },

  /**
   * Извлекает дату и возвращает строку с названием дня недели, числом месяца и названием месяца.
   *
   * @param {string} date Дата, которую необходимо разобрать.
   * @return {string} Строка с названием дня недели, числом месяца и названием месяца.
   * @description Например, "Понедельник, 2 августа".
   */
  parseDate(date) {
    const dateObj = new Date(date);
    const weekdayNames = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
    const weekDayNum = dateObj.getUTCDay();
    const weekDay = weekdayNames[weekDayNum];
    const month = this.parseDayMonth(date, true);

    return weekDay + ", " + month;
  },

  /**
   * Извлекает день и месяц из даты.
   *
   * @param {string} date Дата, которую необходимо разобрать.
   * @param {boolean} monthName Флаг, указывающий на использование названия месяца вместо номера.
   * @return {string} Строка с отформатированным днем и месяцем.
   * @description Например, "02.08" либо "2 августа".
   */
  parseDayMonth(date, monthName) {
    const dateObj = new Date(date);
    const dayNum = dateObj.getUTCDate();
    const day = monthName ? dayNum : this.addLeadingZero(dayNum);
    const monthNames = [
      'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
      'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
    ];
    const monthNum = dateObj.getUTCMonth();
    const month = monthName ? monthNames[monthNum] : this.addLeadingZero(monthNum + 1);
    const separator = monthName ? " " : ".";

    return day + separator + month;
  },

  /**
   * Извлекает день и месяц для диапазона дат.
   *
   * @param {string} startDate Строка, представляющая начальную дату.
   * @param {string} endDate Строка, представляющая конечную дату.
   * @param {boolean} monthName Флаг, указывающий на использование названия месяца вместо номера.
   * @return {string} Строка с отформатированной начальной и конечной датой.
   * @description Например, "01.01 – 01.02" либо "1 января – 2 февраля".
   */
  parseDayMonthRange(startDate, endDate, monthName) {
    const startDayMonth = this.parseDayMonth(startDate, monthName);
    const endDayMonth = this.parseDayMonth(endDate, monthName);

    return startDayMonth + " " + this.SEPARATOR + " " + endDayMonth;
  },

  /**
   * Извлекает название месяца и год из даты.
   *
   * @param {string} date Дата, которую необходимо разобрать.
   * @return {string} Строка с названием месяца и годом.
   * @description Например, "Август, 1993".
   */
  parseMonthNameYear(date) {
    const dateObj = new Date(date);
    const monthNames = [
      'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
      'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
    ];
    const monthNum = dateObj.getUTCMonth();
    const month = monthNames[monthNum];
    const year = dateObj.getUTCFullYear();

    return month + ", " + year;
  },

  /**
   * Извлекает время и возвращает строку в формате "ЧЧ:ММ".
   *
   * @param {string} date Дата, содержащая время, которое необходимо извлечь.
   * @return {string} Строка в формате "ЧЧ:ММ".
   * @description Например, "9:00".
   */
  parseTime(date) {
    const dateObj = new Date(date);
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const formattedMinutes = this.addLeadingZero(minutes);

    return hours + ":" + formattedMinutes;
  },

  /**
   * Извлекает диапазон времени и возвращает строку в формате "ЧЧ:ММ – ЧЧ:ММ".
   *
   * @param {string} startDate Начальная дата и время.
   * @param {string} endDate Конечная дата и время.
   * @return {string} Строка в формате "ЧЧ:ММ – ЧЧ:ММ".
   * @description Например, "9:00 – 12:00".
   */
  parseTimeRange(startDate, endDate) {
    const startTime = this.parseTime(startDate);
    const endTime = this.parseTime(endDate);

    return startTime + " " + this.SEPARATOR + " " + endTime;
  }
};

export default dateTime;
