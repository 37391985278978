/**
 * Объект, представляющий идентификаторы модальных окон.
 */
const ModalId = {
  /**
   * Идентификатор модального окна команды разработчиков.
   * @type {number}
   */
  DEV_TEAM: 1
};

export default ModalId;