import React, { useEffect, useState } from "react";
import styles from "./ErrorAlert.module.css"
import Icon from "../Icon";

/**
 * Компонент всплывающего сообщения об ошибке.
 *
 * @param {string} text Текст уведомления.
 * @return {Element} Элемент уведомления.
 */
const ErrorAlert = ({ children: text }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setIsShown(text !== "");

    const timer = setTimeout(handleClose, 10000);

    return () => clearTimeout(timer);
  }, [text]);

  const handleClose = () => setIsShown(false);

  return (
    <>
      {isShown && (
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <div className={styles.timeBar} />
            <div className={styles.content}>
              <div className={styles.contentLeft}>
                <div>
                  <Icon name={"info"} />
                </div>
                {text}
              </div>
              <div onClick={handleClose}>
                <Icon className={styles.closeButton} name={"x-close"} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ErrorAlert;