import React, { useState } from "react";
import styles from "./Header.module.css";
import CustomList from "../CustomList/CustomList";
import Logo from "../Logo/Logo";
import Icon from "../Icon";
import { useAppContext } from "../../../App";
import { useNavigate } from "react-router-dom";
import useResponse from "../../../api/hooks/useResponse";
import Button from "../Button/Button";
import ButtonType from "../../../api/constants/ButtonType";

/**
 * Компонент для отображения шапки.
 *
 * @param {boolean} backButton Флаг, указывающий на отображение кнопки "Назад".
 * @param {string} title Заголовок шапки.
 * @param {ListItem[]} menuItems Переключаемые элементы меню.
 * @param {Function} onClickMenuItem Обратный вызов, вызываемый при нажатии элемента меню.
 * @return {Element} Элемент шапки.
 */
const Header = ({ backButton, title, menuItems, onClickMenuItem }) => {
  const { theme, toggleTheme } = useAppContext();
  const { isMobile } = useResponse();
  const navigate = useNavigate();
  const hasMenuItems = menuItems?.length > 0;

  /**
   * Компонент для отображения кнопки возвращения к выбору расписания.
   *
   * @return {Element} Элемент кнопки.
   */
  const BackButton = () => {
    const iconName = `${isMobile ? "magnifying-glass" : "chevron-left"}`;

    const handleClick = () => navigate("/");

    return (
      <button className={styles.backButton} onClick={handleClick}>
        <Icon name={iconName} color={"currentColor"} />
        Поиск
      </button>
    );
  }

  /**
   * Компонент кнопки для выбора объекта расписания.
   *
   * @return {Element} Элемент кнопки.
   */
  const SelectButton = () => {
    const [isMenuShown, showMenu] = useState(false);

    const iconName = `chevron-${isMenuShown ? "up" : "down"}`;

    /**
     * Обрабатывает нажатие кнопки.
     */
    const handleClick = () => showMenu(!isMenuShown);

    /**
     * Обрабатывает нажатие по элементу меню.
     *
     * @param {number} index Индекс нажатого элемента.
     * @param {Object} data Индекс нажатого элемента.
     */
    const handleClickMenuItem = (index, data) => onClickMenuItem(data["type"], data["id"]);

    const Menu = () => {
      return (
        <div className={styles.dropdownContent}>
          <CustomList items={menuItems} onItemClick={handleClickMenuItem} />
        </div>
      );
    }

    return (
      <div className={styles.dropdownContainer}>
        {hasMenuItems ? (
          <button className={`${styles.title} ${styles.selectButton}`} onClick={handleClick}>
            {title}
            <Icon name={iconName} size={16} color={"currentColor"} />
          </button>
        ) : (
          <div className={styles.title}>{title}</div>
        )}
        {isMenuShown && <Menu />}
      </div>
    );
  }

  /**
   * Компонент кнопки переключения темы.
   *
   * @return {Element} Элемент кнопки.
   */
  const SwitchThemeButton = () => {
    const iconColor = "currentColor";

    return (
      <Button className={styles.switchThemeButton} type={ButtonType.OUTLINED} onClick={toggleTheme}>
        {theme === "dark" ? (
          <>
            <Icon name={"sun-outlined"} color={iconColor} />
            Светлая тема
          </>
        ) : (
          <>
            <Icon name={"moon"} color={iconColor} />
            Тёмная тема
          </>
        )}
      </Button>
    );
  }

  return (
    <header className={styles.container}>
      <div className={styles.innerContainer}>
        {backButton ? <BackButton /> : <Logo />}
        <SelectButton />
        <SwitchThemeButton />
      </div>
    </header>
  );
}

export default Header;
