import React from "react";
import { Sheet } from "react-modal-sheet";

/**
 * Компонент выдвижной шторки снизу.
 *
 * @param {React.ReactNode} content Содержимое шторки.
 * @param {boolean} isOpen Флаг, указывающий на открытие шторки.
 * @param {Function} onClose Обратный вызов, вызываемый при закрытии шторки.
 * @returns {Element} Компонент шторки.
 * @constructor
 */
const BottomSheet = ({ children: content, isOpen, onClose }) => {
  return (
    <Sheet isOpen={isOpen} detent={"content-height"} onClose={onClose}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          {content}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={onClose} />
    </Sheet>
  );
}

export default BottomSheet;