import React from "react";
import { Link as RouterLink } from "react-router-dom";

/**
 * Компонент открываемой ссылки.
 *
 * @param {React.ReactNode} content Вложенный контент ссылки.
 * @param {string} className Класс для ссылки.
 * @param {string} url URL, на который ссылается ссылка.
 * @param {boolean} newTab Флаг, указывающий на открытие ссылки в новой вкладке.
 * @return {Element} Элемент ссылки.
 */
const Link = ({ children: content, className, url, newTab }) => {
  const target = newTab ? "_blank" : null;
  const rel = target ? "noreferrer noopener" : null;

  return (
    <RouterLink className={className} to={url} target={target} rel={rel}>
      {content}
    </RouterLink>
  );
}

export default Link;