import React, { useEffect, useState } from "react";
import styles from "./Tooltip.module.css";

/**
 * Компонент всплывающей подсказки.
 *
 * @param {React.ReactChildren} children Дочерние элементы, отображаемые внутри подсказки.
 * @param {Object} props Свойства компонента.
 * @param {string} props.title Текст заголовка.
 * @param {number} props.delay Задержа перед показом (в милисекундах). По умолчанию 500 мс.
 * @returns {Element} Элемент подсказки.
 */
const Tooltip = ({ children, title, delay  = 500 }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsShown(true);
    }, delay);

    return () => clearTimeout(timerId);
  }, []);

  return (
    <>
      {isShown && (
        <div className={styles.tooltip}>
          {title && <div className={styles.title}>{title}</div>}
          {children && <div className={styles.text}>{children}</div>}
        </div>
      )}
    </>
  );
}

export default Tooltip;