import { useDocumentTitle } from "usehooks-ts";

/**
 * Хук для установки заголовка страницы.
 *
 * @param {string} text Текст, который будет добавлен к заголовку страницы.
 */
const useTitle = (text) => {
  const defaultTitle = "Расписание КГТУ";
  const title = text ? text + " — " + defaultTitle : defaultTitle;

  useDocumentTitle(title);
}

export default useTitle;
