import React, { createContext, useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SearchPage from "./ui/pages/SearchPage/SearchPage";
import TimetablePage from "./ui/pages/TimetablePage/TimetablePage";
import TimetableObject from "./api/data/TimetableObject";
import DevTeamModal from "./ui/modals/DevTeamModal/DevTeamModal";
import NotFoundPage from "./ui/pages/NotFoundPage/NotFoundPage";
import ModalId from "./api/constants/ModalId";
import useFaviconTheme from "./api/hooks/useFaviconTheme";
import ErrorAlert from "./ui/components/ErrorAlert/ErrorAlert";

const AppContext = createContext(null);

export const useAppContext = () => useContext(AppContext);

/**
 * Компонент приложения.
 *
 * @return {Element} Элемент приложения.
 */
const App = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [theme, setTheme] = useState("light");
  const [visibleModalId, setVisibleModalId] = useState(null);

  useFaviconTheme();

  // Проверяем сохранённые значения в хранилище
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") ?? "light";

    if (theme !== savedTheme) {
      toggleTheme();
    }

    const savedTimetableObject = TimetableObject.getFromLocalStorage();

    if (savedTimetableObject) {
      //navToTimetable(savedTimetableObject.type, savedTimetableObject.id);
    }
  }, []);

  /**
   * Компонент для отображения модальных окон приложения.
   *
   * @return {Element} Элемент модального окна.
   */
  const Modals = () => {
    const handleClose = () => setVisibleModalId(null);

    const isVisible = visibleModalId !== null;
    const list = {
      [ModalId.DEV_TEAM]: <DevTeamModal isVisible={isVisible} onClose={handleClose} />,
    };

    return visibleModalId && list[visibleModalId];
  };

  /**
   * Переключает тему сайта.
   */
  const toggleTheme = () => {
    const value = (theme === "light") ? "dark" : "light";

    document.body.setAttribute("data-theme", value);
    localStorage.setItem("theme", value);
    setTheme(value);
  }

  /**
   * Сохраняет выбранный объект расписания.
   *
   * @param {TimetableObject|null} obj Сохраняемый объект расписания либо `null`.
   */
  const saveTimetableObject = (obj) => {
    if (obj !== null) {
      obj.saveToLocalStorage();
    } else {
      TimetableObject.removeFromLocalStorage();
    }
  }

  /**
   * Показывает заданное модальное окно.
   *
   * @param {ModalId} id ID отображаемого модального окна.
   */
  const showModal = (id) => setVisibleModalId(id);

  const contextValues = {
    theme,
    setErrorMessage,
    toggleTheme,
    showModal
  };

  return (
    <AppContext.Provider value={contextValues}>
      <BrowserRouter>
        <Routes>
          <Route element={<SearchPage />} index />
          <Route path="/timetable" element={<TimetablePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <ErrorAlert>{errorMessage}</ErrorAlert>
        <Modals />
      </BrowserRouter>
      {/*<CookieAlert/>*/}
    </AppContext.Provider>
  );
}

export default App;