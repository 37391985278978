import axios from "axios";

/**
 * Объект для выполнения асинхронных HTTP-запросов с использованием библиотеки axios.
 *
 * @property {Object} cancelToken Токен отмены запроса.
 */
const request = {
  cancelToken: null,

  /**
   * Отправляет HTTP-запрос на сервер.
   *
   * @param {string} post Путь к конечной точке API.
   * @param {Object} data Данные, передаваемые в теле запроса.
   * @param {Function} callback Callback-функция, вызываемая после завершения запроса.
   */
  send(post, data, callback) {
    this.cancelToken?.cancel();

    this.cancelToken = axios.CancelToken.source();

    axios.post(
      "https://api.kstuapp.ru/web/v1" + post,
      data,
      {
        cancelToken: this.cancelToken.token
      }).then(result => {
        const resultData = result?.data;

        //console.log("Полученный ответ:", resultData);
        callback(resultData, "");
      }).catch(error => {
        if (!axios.isCancel(error)) {
          //console.error("Ошибка запроса:", error);

          const errorData = error?.response?.data?.error;
          const description = errorData
            ? `Ошибка ${errorData.code}: ${errorData.message}`
            : "Произошла непредвиденная ошибка";

          callback(null, description);
        }
      });
  },

  /**
   * Отменяет отправленный запрос.
   */
  cancel() {
    this.cancelToken?.cancel();
  },

  /**
   * Выполняет поиск объектов расписания по заданному запросу.
   *
   * @param {string} query Строка запроса для поиска.
   * @param {Function} callback Callback-функция, вызываемая после завершения поиска.
   */
  search(query, callback) {
    this.send(
      "/structure/search/",
      { search: query },
      callback
    );
  },

  /**
   * Получает расписание для указанного типа и идентификатора.
   *
   * @param {string} type Тип объекта расписания.
   * @param {number} id ID объекта расписания.
   * @param {string} weekISO Неделя расписания в формате ISO 8601. Например, "2024-W3".
   * @param {Function} callback Callback-функция, вызываемая после получения расписания.
   */
  getTimetable(type, id, weekISO, callback) {
    this.send(
      "/timetable/week/",
      {
        type: type,
        id: id,
        weekISO: weekISO
      },
      callback
    );
  }
};

export default request;