import dateTime from "../utils/dateTime";

/**
 * Класс, представляющий элемент переключателя недели.
 */
export default class WeekSwitcherItem {
  constructor(title, shortWeekRange, weekRange) {
    /**
     * Заголовок элемента переключателя.
     *
     * @type {string}
     * @description Например, "Январь, 2024".
     */
    this.title = title;

    /**
     * Сокращённый диапазон начала и конца недели.
     *
     * @type {string}
     * @description Например, "29.01 – 04.02, I".
     */
    this.shortWeekRange = shortWeekRange;

    /**
     * Диапазон начала и конца недели.
     *
     * @type {string}
     * @description Например, "29.01 – 04.02, I неделя".
     */
    this.weekRange = weekRange + " неделя";
  }

  static parse(weekData) {
    const startDate = weekData["startDate"];
    const endDate = weekData["endDate"];
    const title = dateTime.parseMonthNameYear(startDate);
    const monthRange = dateTime.parseDayMonthRange(startDate, endDate, false);
    const weekNames = { 0: "", 1: ", I", 2: ", II" };
    const evenType = weekData["even"];
    const weekRange = monthRange + weekNames[evenType];

    return new this(title, weekRange, weekRange);
  }
}
